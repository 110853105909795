import { Link } from "gatsby";
import React from "react";
import Slider from "react-slick";
import { learnSlidersSettings } from "../constants";
import { sanatizeSearchText } from "../utilities/helpers";
import "../styles/page/learn_page.scss";

export const HeadingGradientForLearn = ({ headingHTML }) => {
  return (
    <div className="pricing_content">
      <div
        className="text-3.25xl font-semibold text-gray-2700 font-manrope tracking-tighter_3 learning-hub w- leading-10 w-[235px] sm:w-auto sm:text-4.5xl"
        dangerouslySetInnerHTML={{ __html: headingHTML }}
      />
    </div>
  );
};

export const HeadingGradientForLearnCTA = ({ headingHTML }) => {
  return (
    <span
      className="font-semibold learn_heading_gradient "
      dangerouslySetInnerHTML={{ __html: headingHTML }}
    />
  );
};

const LearnCard = ({
  index,
  id,
  cardTime,
  cardHeading,
  cardDescription,
  thumbnail,
  cardTag,
  isSubDescription,
  searchText,
  slug,
}) => {
  const regex = new RegExp(sanatizeSearchText(searchText), "iu");

  const content = cardHeading?.replace(
    regex,
    `<span style="background:yellow">${cardHeading?.match(regex)}</span>`
  );
  return (
    <div className="flex flex-col rounded-2xl bg-white card-3d shadow-lg max-w-xs min-h-[466px]">
      <div className="relative h-[210px]">
        <Link
          to={`/learning-hub/${slug?.text}`}
          state={{ id, index }}
          className="relative w-full h-full"
        >
          {!thumbnail ? (
            <div className="w-full rounded-t-2xl h-full bg-[#F8F8FF]" />
          ) : (
            <img
              src={thumbnail}
              alt={"video-thumbnail"}
              className="h-full w-full rounded-t-2xl object-cover bg-[#F8F8FF]"
              loading="lazy"
              width={0}
              height={0}
            />
          )}
        </Link>
      </div>
      <div className="flex flex-col items-start flex-1 px-5 py-5">
        <div className="flex items-center justify-between w-full mb-5">
          <Link
            to={`/learning-hub/${slug?.text}`}
            state={{ id, index }}
            className="text-sm leading-4 text-purple-500"
          >
            {cardTag}
          </Link>
          <>
            <p className="text-xs leading-4 text-[#455066]">{cardTime}</p>
          </>
        </div>

        <div className="flex flex-col justify-between flex-1">
          <Link
            to={`/learning-hub/${slug?.text}`}
            state={{ id, index }}
            className="text-xl font-semibold card-header-line-clamp hover:text-purple-500"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Link>
        </div>

        {isSubDescription && (
          <div className="flex flex-col flex-1 justify-between py-2.5 text-start">
            <Link
              to={`/learning-hub/${slug?.text}`}
              state={{ id, index }}
              className="text-base font-normal hover:text-purple-500 card-description-line-clamp"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: cardDescription,
                }}
              />
            </Link>
          </div>
        )}

        <div className="flex gap-1 flex-wrap justify-between w-full items-center mt-2.5">
          <Link
            to={`/learning-hub/${slug?.text}`}
            state={{ id, index }}
            className="font rounded-full border-2 border-[#5658DD] p-2.5 text-sm font-semibold uppercase tracking-wider text-purple-500 hover:bg-[#5658dd] hover:text-white"
          >
            Watch Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export const LearnFooterCTAComponent = ({ heading, description }) => {
  return (
    <article className="blog_cta_bg_gradient max-w-7xl w-full mx-auto lg:rounded-2xl py-24 px-2.5 md:px-0 mb-56 lg:mb-40">
      <section className="flex flex-col items-center w-full max-w-2xl mx-auto text-center">
        <span className="text-2xl leading-9 text-white md:text-4xl md:leading-11">
          <HeadingGradientForLearnCTA headingHTML={heading?.html} />
        </span>

        <span className="pt-6 text-sm font-normal text-white md:text-base md:pb-9 pb-14">
          {description?.text}
        </span>

        <Link
          to="/try-it-free"
          className="btn-gradiant text-sm border-none rounded-full shadow-lg text-white py-2.5 px-10"
        >
          Book a demo
        </Link>
      </section>
    </article>
  );
};

export const LearnSliderComponent = ({
  isSearch,
  isDivider,
  learning_hub_item,
  searchText,
  setSearchText,
}) => {
  return (
    <article className="w-full max-w-xs sm:max-w-xl pb-8 mx-auto lg:max-w-7xl md:max-w-2xl">
      <section className="pt-5 lg:pb-20 md:pb-16 pb-14">
        <div className="flex flex-col justify-between md:flex-row">
          <div className="flex flex-col ml-2.5">
            <div className="text-xl font-semibold text-purple-1000 md:text-2xl">
              {learning_hub_item?.primary?.heading?.text}
            </div>

            <div className="text-base font-normal text-purple-1000">
              {learning_hub_item?.primary?.description?.text}
            </div>
          </div>
          {isSearch && (
            <div className="hidden items-center relative mb-2.5 lg:flex">
              <input
                placeholder="Search..."
                className="w-full h-10 rounded-lg lg:w-64 search_input bg-inherit"
                type="text"
                onChange={(event) => {
                  setSearchText && setSearchText(event.target.value);
                }}
                value={searchText}
              />
              <svg
                xmlns="https://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#BFBFBF"
                className="w-6 h-6 absolute left-2.5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </div>
          )}
        </div>

        {!!learning_hub_item?.items?.length && (
          <Slider {...learnSlidersSettings} className="learn-list-container">
            {learning_hub_item?.items?.map((item, key) => {
              return (
                <LearnCard
                  slug={item.slug}
                  index={key}
                  id={learning_hub_item?.id}
                  thumbnail={item?.thumbnail?.url}
                  key={key}
                  searchText={searchText}
                  embedded_iframe={item?.embedded_iframe}
                  topCardHeading={item?.card_heading?.text}
                  cardTime={item?.video_time?.text}
                  cardHeading={item?.card_heading?.text}
                  cardDescription={item?.card_description?.text}
                  cardTag={item?.tag?.text}
                  isSubDescription={true}
                />
              );
            })}
          </Slider>
        )}
      </section>

      {isDivider && <div className="h-px bg-gray-1400" />}
    </article>
  );
};
